import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import AdjustPoints from 'src/components/adjust-points/adjust-points';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const AdjustPointsPage = () => {
  return (
    <Router basepath="/players/:id">
      <AdjustPointsRoute path="/adjust-points" />
    </Router>
  );
};

interface AdjustPointsRouteProps extends RouteComponentProps {
  id?: string;
}

const AdjustPointsRoute: React.FC<AdjustPointsRouteProps> = ({ id }) => {
  return (
    <Layout>
      <SEO title="Adjust points" />
      <PrivateRoute>
        <AdjustPoints id={id} />
      </PrivateRoute>
    </Layout>
  );
};

export default AdjustPointsPage;
